import { FormatDateOptions } from '@formatjs/intl/src/types'
import { FormatNumberOptions, IntlShape, MessageDescriptor } from 'react-intl'
import { I18nId } from '@/i18n/config'

/**
 * 覆蓋為 `id: I18nId` 以利後續 react-intl.formatter 可以正確建立 typing 連結
 *
 * 能夠有利於未來重構 i18n id 時，避免遺漏，或避免拼寫錯字。
 *
 * 例如 `intl.formatMessage({ id })` 和 `<FormattedMessage id='' />` 可以使 compiler
 * 正確探知其 id 是否拼寫正確或存在。
 *
 */
interface OurMessageDescriptor extends MessageDescriptor {
  id: I18nId
}

export type FormatDateType =
  | 'default'
  | 'dateTimeShort'
  | 'dateTimeMedium'
  | 'dateTimeLong'
  | 'numericMonthDay'
  | 'localeYearMonth'
  | 'localeMonth'

export type FormatDateRangeType = Exclude<
  FormatDateType,
  'localeYearMonth' | 'localeMonth'
>

export const FORMAT_DATE_TYPE_OPTION_MAP: Record<
  FormatDateType,
  FormatDateOptions
> = {
  default: {
    dateStyle: 'short',
  },
  dateTimeShort: {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: false,
  },
  dateTimeMedium: {
    dateStyle: 'short',
    timeStyle: 'medium',
  },
  dateTimeLong: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
  numericMonthDay: {
    month: 'numeric',
    day: 'numeric',
  },
  localeYearMonth: {
    year: 'numeric',
    month: 'long',
  },
  localeMonth: {
    month: 'short',
  },
}

export type OurIntlFormatNumberOptions = FormatNumberOptions & {
  isCompactDisplay?: boolean
  isDiscountRateDisplay?: boolean
  isPercentageDisplay?: boolean
  percentagePrecision?: number
  treatNaNAsZero?: boolean
  style?: Exclude<FormatNumberOptions['style'], 'percent'>
}

/**
 * 建立 I18nId typing 連結至 react-intl.IntlShape
 *
 * 以利後續建立 intl id typing 連結。
 *
 * ```
 * type I18nId =
 *   | 'default.button.save'
 *   | 'default.button.dismiss'
 *
 * intl.formatMessage({ id: i18nId })
 * intl.formatMessage({ id: 'default.button.save' }) // expect ok
 * intl.formatMessage({ id: 'button.save' }) // expect error, id not exist
 * ```
 */
export type OurIntlShape = {
  formatMessage(
    descriptor: OurMessageDescriptor,
    values?: Parameters<IntlShape['formatMessage']>['1'],
  ): string
  formatNumber(value: number, opts?: OurIntlFormatNumberOptions): string
  /**
   * @description
   * example:
   * - default: 2024/5/3
   * - dateTimeShort: 2024/5/3 12:00
   * - dateTimeLong: 2024/5/3 11:59:59
   * - numericMonthDay: 5/3
   * - LocaleYearMonth: 2024年5月
   * - LocaleMonth: 5月
   * */
  formatDate(
    value: Parameters<Intl.DateTimeFormat['format']>[0] | string,
    type?: FormatDateType,
  ): string
  /**
   * @description
   * example:
   * - default: 2024/5/3 - 2024/5/5
   * - dateTimeShort: 2024/5/3 12:00 - 2024/5/5 12:00
   * - dateTimeLong: 2024/5/3 11:59:59 - 2024/5/5 11:59:59
   * - numericMonthDay: 5/3 - 5/5
   * */
  formatDateTimeRange(
    from: Parameters<Intl.DateTimeFormat['format']>[0] | string,
    to: Parameters<Intl.DateTimeFormat['format']>[0] | string,
    type?: FormatDateRangeType,
  ): string
} & Omit<
  IntlShape,
  'formatMessage' | 'formatNumber' | 'formatDate' | 'formatDateTimeRange'
>
