import { notification } from 'antd'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash-es'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useMount } from 'react-use'
import { useFetchUserStatusQuery } from '@/api/user-api'
import { MultipleLoginModal } from '@/components/multiple-login/multiple-login-modal'
import useUserTokenChange from '@/components/multiple-login/use-user-token-change'
import UnauthorizedModal from '@/components/unauthorized-modal'
import { CookieName } from '@/constants/cookie-name'
import { NotificationContext } from '@/hooks/antd/use-notification'
import { useAuth } from '@/hooks/use-auth'
import { useOgInfo } from '@/hooks/use-og-info'
import env from '@/utils/env'

interface UserLayoutProps {
  children: ReactNode
}

export const UserLayout: FunctionComponent<UserLayoutProps> = (props) => {
  const { title, description } = useOgInfo()
  useUserTokenChange()
  const router = useRouter()
  const { userInfo } = useAuth()
  const { data: userStatus } = useFetchUserStatusQuery()
  const [notificationApi, notificationHolder] = notification.useNotification({
    bottom: 90,
  })

  useEffect(() => {
    if (isEmpty(window.Beacon)) {
      return
    }

    if (!isEmpty(userInfo)) {
      const identifyData = {
        email: userInfo.email,
        name: userInfo.username,
        company: userInfo.company,
        jobTitle: userInfo.jobTitle,
        industry: userInfo.industry,
        region: userInfo.country,
        'user-id': userInfo.id,
        'user-type': userInfo.type,
        'workspace-id': userStatus?.currentWorkspace.id,
        'workspace-name': userStatus?.currentWorkspace.name,
        'workspace-plan': userStatus?.currentWorkspace.workspaceType,
      }

      window.Beacon('identify', identifyData)
    } else {
      window.Beacon('logout')
    }
  }, [
    userInfo,
    userStatus?.currentWorkspace.id,
    userStatus?.currentWorkspace.name,
    userStatus?.currentWorkspace.workspaceType,
  ])

  useMount(() => {
    router.events.on('routeChangeComplete', (routeUrl) => {
      // 請見 https://github.com/zeit/next.js/blob/v9.1.6-canary.11/packages/next-plugin-google-analytics/src/on-init-client.js
      setTimeout(() => {
        window.gtag('set', {
          ['page_location']: routeUrl,
          ['page_title']: document.title,
        })
        window.gtag('config', process.env.NEXT_PUBLIC_GTAG_ID || 'N/A')
        window.gtag('config', process.env.NEXT_PUBLIC_GA4_ID || 'N/A')
        window.gtag('config', env.GOOGLE_ADS_ID)
      }, 0)
    })

    const { channel } = router.query
    if (channel) {
      Cookies.set(CookieName.Channel, channel as string, { path: '/' })
    }
  })

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name='description' />
      </Head>
      <MultipleLoginModal />
      <UnauthorizedModal />
      <NotificationContext.Provider value={notificationApi}>
        {notificationHolder}
        {props.children}
      </NotificationContext.Provider>
    </>
  )
}
