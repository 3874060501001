import { notification } from 'antd'
import { createContext, useContext } from 'react'

type NotificationInstance = ReturnType<typeof notification.useNotification>[0]

export const NotificationContext =
  createContext<NotificationInstance>(notification)

const useNotification = (): NotificationInstance =>
  useContext(NotificationContext)

export default useNotification
