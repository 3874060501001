import { z } from 'zod'
import { unlimitedCountryCodeEnumSchema } from '@/constants/country-code'
import { Gender } from '@/constants/gender'
import { VerifiedKol } from '@/constants/verified-kol'
import { Page } from '@/hooks/use-authorization/constants'
import { sorterParamsSchema } from '@/types/schema/api/sorter-params-schema'
import { PlatformShortcode } from '@/utils/convert-platform'
import makeRoute, { emptySchema } from '@/utils/routes/make-route'

export const kolManagementSearchParamsSchema = z
  .object({
    platforms: z.nativeEnum(PlatformShortcode).array().optional(),
    customized_tags_names: z.string().optional(),
    country_code: unlimitedCountryCodeEnumSchema.optional(),
    gender: z.enum([Gender.Female, Gender.Male, Gender.Else]).optional(),
    follower_start_from: z.coerce.number().optional(),
    follower_end_to: z.coerce.number().optional(),
    verified_kol: z
      .enum([VerifiedKol.Verified, VerifiedKol.Unverified])
      .optional(),
    engagement_rate_greater_than: z.coerce.number().optional(),
    engagement_rate_less_than_or_equal: z.coerce.number().optional(),
    view_rate_greater_than: z.coerce.number().optional(),
    view_rate_less_than_or_equal: z.coerce.number().optional(),
    follow_count_growth_rate_greater_than: z.coerce.number().optional(),
    follow_count_growth_rate_less_than_or_equal: z.coerce.number().optional(),
    is_unlocked: z.coerce.boolean().optional(),
    min_collaboration_compensation: z.coerce.number().optional(),
    max_collaboration_compensation: z.coerce.number().optional(),
    min_quotation_price: z.coerce.number().optional(),
    max_quotation_price: z.coerce.number().optional(),
  })
  .merge(sorterParamsSchema)

const kolManagementRoute = makeRoute(
  Page.KolManagement,
  emptySchema,
  kolManagementSearchParamsSchema,
)

export default kolManagementRoute
