import { FunctionComponent } from 'react'
import { useMount, useUnmount } from 'react-use'
import styled from 'styled-components'
import ResourcesSelector, {
  ResourcesSelectorProps,
} from '@/components/common/selectors/resources-selector'
import {
  getCollectionList,
  setCollectionList,
} from '@/components/search/filters/utils/exclusive-collection-operator'
import { Condition } from '@/types/api/search'

type ExclusionCollectionFilterType = {
  initialListValue: Condition['exclude_kol_collection_folder_ids']
  onExcludeCollectionListChange: (
    list: Condition['exclude_kol_collection_folder_ids'],
  ) => void
}

const ExclusionCollectionFilter: FunctionComponent<
  ExclusionCollectionFilterType
> = ({ initialListValue = 'all', onExcludeCollectionListChange }) => {
  const collectionList = getCollectionList(initialListValue.split(','))

  const handleFilterCollectionListChange = (
    list: ResourcesSelectorProps['value'],
  ): void => {
    setCollectionList(list ?? [])
    onExcludeCollectionListChange(
      list === 'all' ? 'all' : list?.map((item) => item.id).join(','),
    )
  }

  useMount(() => {
    handleFilterCollectionListChange(collectionList ?? 'all')
  })

  useUnmount(() => {
    setCollectionList('all')
    onExcludeCollectionListChange(undefined)
  })

  return (
    <Wrapper>
      <ResourcesSelector
        maxTagCount={3}
        selectAllI18nId='general:all_collections'
        type='collection'
        value={collectionList}
        onChange={handleFilterCollectionListChange}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 10px;
  padding-left: 24px;

  .ant-select {
    width: 100%;
  }
`

export default ExclusionCollectionFilter
