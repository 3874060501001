import isEmpty from 'lodash-es/isEmpty'
import isUndefined from 'lodash-es/isUndefined'
import { useCallback, useMemo } from 'react'
import { parseCustomizedTagRouterName } from '@/components/billboard/utility/parse-customized-tag-router-name'
import { CUSTOMIZED_TAG_I18N_MAP } from '@/constants/customized-tag'
import { MOCK_CUSTOMIZED_TAG_NAME_PREFIX } from '@/constants/mock-data/kol-info'
import { I18nId } from '@/i18n/config'
import { useIntl } from '@/i18n/hooks/use-intl'
import { PlatformData } from '@/tests/mock-data/mock-content-distribution'
import searchCustomizedTagNames, {
  CustomizedTagNameSearchData,
} from '@/utils/search-customized-tag-names'

interface UseCustomizedTag {
  getCustomizedTagLocalizedName: (tag: string) => string
  getCustomizedTagByRouterName: (
    routerName: string,
  ) => CustomizedTagNameSearchData | undefined
  tags: CustomizedTagNameSearchData[]
  tagsGroupByParent: Record<string, CustomizedTagNameSearchData[]>
  searchCustomizedTagNames: (keyword: string) => string[]
  parseFilterTags: (filterNames?: string) => string[]
  getSubTagsWithRatioTooltip: (
    subCustomizedTags: PlatformData['subCustomizedTags'],
  ) => string
}

const useCustomizedTag = (): UseCustomizedTag => {
  const { formatMessage, formatNumber } = useIntl()

  const tags = useMemo((): CustomizedTagNameSearchData[] => {
    return Object.entries(CUSTOMIZED_TAG_I18N_MAP).map(
      (entry): CustomizedTagNameSearchData => {
        const [name, i18nId] = entry
        return {
          name,
          localizedName: formatMessage({ id: i18nId }),
          routerName: parseCustomizedTagRouterName(name),
        }
      },
    )
  }, [formatMessage])

  const tagsGroupByParent: Record<string, CustomizedTagNameSearchData[]> =
    useMemo(() => {
      return tags.reduce((acc, cur) => {
        const [parent, child] = cur.name.split('/')
        if (child) {
          acc[parent] = acc[parent] ?? []
          acc[parent].push(cur)
        } else {
          acc[cur.name] = acc[cur.name] ?? [cur]
        }
        return acc
      }, {})
    }, [tags])

  /**
   * @param tag 有些地方會直接丟 undefined 進來（但 type 是 any, e.g. `components/search/filters/customized-tag-search.tsx:233`），所以直接改成 optional
   * @returns The localized name of the tag.
   */
  const getCustomizedTagLocalizedName = useCallback(
    (tag?: string): string => {
      if (isUndefined(tag)) {
        return ''
      }

      const mapResult: I18nId | undefined =
        CUSTOMIZED_TAG_I18N_MAP[
          tag.replace(MOCK_CUSTOMIZED_TAG_NAME_PREFIX, '')
        ]
      if (isUndefined(mapResult)) {
        return tag
      }

      return formatMessage({ id: mapResult })
    },
    [formatMessage],
  )

  const getSubTagsWithRatioTooltip = (
    subCustomizedTags: PlatformData['subCustomizedTags'],
  ): string => {
    return subCustomizedTags
      .map(
        ({ customizedTagName, customizedTagPostCountRatio }) =>
          `${getCustomizedTagLocalizedName(customizedTagName)} ${formatNumber(
            customizedTagPostCountRatio,
            {
              isPercentageDisplay: true,
            },
          )}`,
      )
      .join(',')
  }

  const getCustomizedTagByRouterName = useCallback(
    (routerName: string): CustomizedTagNameSearchData | undefined => {
      return tags.find((tag) => tag.routerName === routerName)
    },
    [tags],
  )

  return {
    tags,
    tagsGroupByParent,
    getCustomizedTagByRouterName,
    getCustomizedTagLocalizedName,
    getSubTagsWithRatioTooltip,
    parseFilterTags: (filterNames?: string): string[] =>
      filterNames?.split(/[,|]/g).filter((x) => !isEmpty(x)) ?? [],
    searchCustomizedTagNames: useCallback(
      (keyword: string): string[] => searchCustomizedTagNames(keyword, tags),
      [],
    ),
  }
}

export default useCustomizedTag
