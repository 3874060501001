import { z } from 'zod'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'

const relatedKolTypeSchema = z.enum(['brand', 'person'])
export type RelatedKolType = z.infer<typeof relatedKolTypeSchema>

export const relatedKolSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  follower: z.number(),
  kolType: relatedKolTypeSchema,
  rank: z.number(),
  mention_count: z.number(),
  platform_name: z.string(),
  etl_at: z.string().nullable(),
})

export const relatedKolListSchema = listResourceSchema.extend({
  data: relatedKolSchema.array(),
})
export type RelatedKolList = z.infer<typeof relatedKolListSchema>
