import { AxiosResponse } from 'axios'
import { omit } from 'lodash-es'
import { ApiCaller } from '@/api/executor/api-caller'
import { AppContext } from '@/api/executor/app-context'
import { invalidateUserInfoAction } from '@/api/user-api'
import { CrawlerStatus } from '@/constants/crawler-status'
import { cacheJWT, cacheLoginTime } from '@/store/me'
import { ApiConfig } from '@/types/api-config'
import {
  CompleteRegistrationResponse,
  LoginRequest,
  LoginResponse,
  RegisterResponse,
} from '@/types/api-v1-types'
import {
  QuickSearchResponse,
  VerifiedRadarSearchResponse,
} from '@/types/api/search'
import {
  CampaignContentRequest,
  CampaignContentResponse,
} from '@/types/campaign-content'
import { CampaignCrawlerJob, CampaignReport } from '@/types/campaign-crawler'
import {
  ChannelList,
  GetChannelListRequest,
  GetNotificationtRequest,
  GuestAssignmentSliceRequestObject,
  ModelWorkspace,
  Notification,
  UpdateCampaignKolContractRequest,
  UpdateCampaignKolPaymentRequest,
  UpdateCampaignKolRequest,
} from '@/types/mock-api-types'
import {
  CheckPasswordExistResponse,
  UpdatePasswordPayload,
} from '@/types/password'
import { YearBuySchema } from '@/types/schema/payment-schema'
import {
  RegisterSchema,
  SocialRegisterSchema,
} from '@/types/schema/register-schema'
import { Report } from '@/types/schema/report-schema'
import { UserSchema } from '@/types/schema/user-schema'
import { ValuationSchema } from '@/types/schema/valuation-schema'
import { decamelizeKeys } from '@/utils/humps'

/**
 * @deprecated
 * use RTK Query
 */
export class ApiExecutor {
  private readonly caller: ApiCaller

  constructor(config: ApiConfig) {
    this.caller = new ApiCaller(config)
  }

  // V1

  // USER API

  public verifyAccount(token: string): Promise<AxiosResponse> {
    return this.caller.axios.put('/v2/user/registration-verification', {
      token,
    })
  }

  public login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return this.caller.axios.post<LoginResponse>('/v2/user/login', data)
  }

  public getUserInfo(): Promise<UserSchema> {
    return this.caller.axios
      .get<UserSchema>('/v2/user/me')
      .then((res) => res.data)
  }

  /**
   * @todo
   * 1. refactor to RTKQ
   */
  public refreshToken(token?: string): Promise<AxiosResponse> {
    return this.caller.axios
      .put('/v2/user/refresh', {
        token: token ?? AppContext.getStore()?.getState().me.jwt,
      })
      .then((res) => {
        const freshToken = res.data.token
        const loginTime = res.data.createTime
        AppContext.getStore().dispatch(cacheJWT(freshToken))
        AppContext.getStore().dispatch(cacheLoginTime(loginTime))
        AppContext.getStore().dispatch(invalidateUserInfoAction)
        return res
      })
  }

  public logout(): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/logout')
  }

  public updateUser(data: Partial<UserSchema>): Promise<AxiosResponse> {
    return this.caller.axios.put('/v2/user/me', decamelizeKeys(data))
  }

  public completeRegistration(
    data: Partial<UserSchema>,
  ): Promise<AxiosResponse<CompleteRegistrationResponse>> {
    return this.caller.axios.put<CompleteRegistrationResponse>(
      '/v2/user/registration-completion',
      decamelizeKeys(data),
    )
  }

  public resetPassword(email: string): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/password', { email })
  }

  public register(
    data: RegisterSchema,
  ): Promise<AxiosResponse<RegisterResponse>> {
    return this.caller.axios.post<RegisterResponse>(
      '/v2/user/register',
      decamelizeKeys(data),
    )
  }

  public resendVerifyEmail(email: string): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/resend-verification', { email })
  }

  public updatePassword(
    data: UpdatePasswordPayload,
  ): Promise<AxiosResponse<UpdatePasswordPayload>> {
    return this.caller.axios.put<UpdatePasswordPayload>(
      '/v2/user/password',
      decamelizeKeys(data),
    )
  }

  public checkHasPW(): Promise<AxiosResponse<CheckPasswordExistResponse>> {
    return this.caller.axios.get<CheckPasswordExistResponse>(
      '/v2/user/password',
    )
  }

  public searchKOLs(
    keyword: string,
    workspaceID?: number,
    limit = 60,
    countryCode?: string,
    platform?: string,
  ): Promise<AxiosResponse<QuickSearchResponse>> {
    return this.caller.axios.get<QuickSearchResponse>(
      `/v2/workspaces/${workspaceID}/kol/quick-search`,
      {
        params: { keyword, limit, countryCode, platform },
      },
    )
  }

  public searchVerifiedKOLs(
    keyword: string,
    workspaceID?: number,
    limit = 60,
    countryCode?: string,
  ): Promise<AxiosResponse<VerifiedRadarSearchResponse>> {
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceID}/kol/radar-search-verified`,
      {
        params: { keyword, limit, countryCode },
      },
    )
  }

  public updateValuation(
    data: ValuationSchema,
    workspaceID?: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.put(
      `/v2/workspaces/${workspaceID}/valueEstimation`,
      data,
    )
  }

  // Campaign API
  public fetchCampaignContractTemplates(
    page: number,
    campaignId: number,
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-contract-templates`,
      {
        params: { page },
      },
    )
  }

  public updateCampaignKols(
    campaignKol: Partial<UpdateCampaignKolRequest>,
    campaignKolIds: number[],
    campaignId: number,
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.patch(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-kols`,
      decamelizeKeys({ campaignKolIds, campaignKol }),
    )
  }

  public updateCampaignKolContracts(
    campaignKolContract: UpdateCampaignKolContractRequest,
    campaignKolContractID: string,
    campaignKolId: number,
    campaignId: number,
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.patch(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-kols/${campaignKolId}/campaign-kol-contracts/${campaignKolContractID}`,
      decamelizeKeys({ ...campaignKolContract }),
    )
  }

  public createCampaignKolContracts(
    campaignKolContract: UpdateCampaignKolContractRequest,
    campaignKolIds: number[],
    campaignId: number,
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.post(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-kols/campaign-kol-contracts`,
      decamelizeKeys({ campaignKolIds, campaignKolContract }),
    )
  }

  public removeCampaignKOLs(
    campaignId: number,
    campaignKolIds: number[],
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.delete(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-kols`,
      {
        params: decamelizeKeys({ campaignKolIds }),
      },
    )
  }

  public deleteCampaignKolContracts(
    campaignId: number,
    campaignKolContractID: number,
    campaignKolID: number,
    workspaceID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.delete(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignId}/campaign-kols/${campaignKolID}/campaign-kol-contracts/${campaignKolContractID}`,
    )
  }

  // Campaign KOL API
  /**
   * @deprecated use useAddCampaignKolsMutation hook instead
   */
  public addCampaignKol(
    workspaceId: number,
    campaignId: number,
    kolUUIDs: string[],
  ): Promise<AxiosResponse> {
    return this.caller.axios.post(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols`,
      decamelizeKeys({ kolUUIDs }),
    )
  }

  public updateCampaignKolPayment(
    campaignKol: UpdateCampaignKolPaymentRequest,
    campaignKolIds: number[],
    campaignId: number,
    workspaceId: number,
    requestPaymentDate?: string,
  ): Promise<AxiosResponse> {
    return this.caller.axios.post(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols/payment`,
      decamelizeKeys({
        campaignKolIds,
        campaignKol,
        requestPaymentDate,
      }),
    )
  }

  public exportShipmentData(params: {
    workspaceId: number
    campaignId: number
    campaignKolIds: number[]
  }): Promise<AxiosResponse> {
    const { workspaceId, campaignId, campaignKolIds } = params
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols/recipient-infos`,
      {
        responseType: 'arraybuffer',
        params: decamelizeKeys({
          campaignKolIds,
        }),
      },
    )
  }

  public downloadAttachments(params: {
    workspaceId: number
    campaignId: number
    campaignKolIds: number[]
  }): Promise<AxiosResponse> {
    const { workspaceId, campaignId, campaignKolIds } = params
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols/attachments`,
      {
        responseType: 'arraybuffer',
        params: decamelizeKeys({
          campaignKolIds,
        }),
      },
    )
  }

  public exportAccountingData(params: {
    workspaceId: number
    campaignId: number
    campaignKolIds: number[]
  }): Promise<AxiosResponse> {
    const { workspaceId, campaignId, campaignKolIds } = params
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/campaign-kols/payment-infos`,
      {
        responseType: 'arraybuffer',
        params: decamelizeKeys({
          campaignKolIds,
        }),
      },
    )
  }

  // Chatroom API
  public getChannelList(
    request: GetChannelListRequest,
  ): Promise<AxiosResponse<ChannelList>> {
    return this.caller.axios.get(
      `/v2/workspaces/${request.workspaceId}/campaigns/${request.campaignId}/channel-list`,
    )
  }

  public fetchNotifications(
    request: GetNotificationtRequest,
  ): Promise<AxiosResponse<Notification>> {
    const { workspaceId, ...params } = request

    return this.caller.axios.get(
      `/v2/workspaces/${workspaceId}/notifications`,
      {
        params: decamelizeKeys(params),
      },
    )
  }

  // Campaign Report / Content API

  public getCampaignReport(
    campaignID: number,
    workspaceID?: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.get<CampaignReport>(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignID}/report`,
    )
  }

  /**
   * GET /v2/workspaces/{workspaceId}/campaigns/{campaignId}/content
   */
  public getCampaignCrawlerStatus(
    campaignId: number,
    workspaceId: number,
    statuses: CrawlerStatus[] = [CrawlerStatus.Succeeded],
    unread = false,
  ): Promise<AxiosResponse<CampaignCrawlerJob[]>> {
    return this.caller.axios.get(
      `/v2/workspaces/${workspaceId}/campaigns/${campaignId}/crawler-status`,
      {
        params: {
          statuses,
          unread,
        },
      },
    )
  }

  /**
   * GET /v2/workspaces/{workspaceID}/campaigns/{campaignID}/content
   */
  public getCampaignContent(
    request: CampaignContentRequest,
  ): Promise<AxiosResponse<CampaignContentResponse>> {
    return this.caller.axios.get(
      `/v2/workspaces/${request.workspaceID}/campaigns/${request.campaignID}/content`,
      {
        params: decamelizeKeys({
          ...omit(request, ['workspaceID', 'campaignID']),
        }),
      },
    )
  }

  public triggerCrawler(
    campaignID: number,
    workspaceID: number,
  ): Promise<AxiosResponse<Report>> {
    return this.caller.axios.patch<Report>(
      `/v2/workspaces/${workspaceID}/campaigns/${campaignID}/campaign-kol-posts/trigger-crawler`,
    )
  }

  // PAYMENT API
  public planConsult(
    data: YearBuySchema,
    workspaceID?: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.post(
      `/v2/workspaces/${workspaceID}/consulting`,
      data,
    )
  }

  public checkEmailAvailable(data: {
    oAuthType?: string
    email: string
  }): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/email', data)
  }

  public socialLogin(data: SocialRegisterSchema): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/social/login', data)
  }

  public socialBind(data: unknown): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/social/bind', data)
  }

  public socialUnbind(data: { oAuthType: string }): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/user/social/unbind', data)
  }

  public uploadFile(data: unknown): Promise<AxiosResponse> {
    return this.caller.axios.post('/v2/file', data)
  }

  // Workspace

  public updateWorkspace(
    workspaceID: number,
    workspace: Partial<ModelWorkspace>,
  ): Promise<ModelWorkspace> {
    return this.caller.axios.patch<Partial<ModelWorkspace>, ModelWorkspace>(
      `/v2/workspaces/${workspaceID}`,
      workspace,
    )
  }

  public inviteWorkspaceGuest(
    workspaceID: number,
    data: GuestAssignmentSliceRequestObject,
  ): Promise<AxiosResponse<GuestAssignmentSliceRequestObject>> {
    return this.caller.axios.post<GuestAssignmentSliceRequestObject>(
      `/v2/workspaces/${workspaceID}/guestAssignments`,
      data,
    )
  }

  public removeGuestFromReport(
    workspaceID: number,
    guestAssignmentID: number,
  ): Promise<AxiosResponse> {
    return this.caller.axios.delete(
      `/v2/workspaces/${workspaceID}/guestAssignments/${guestAssignmentID}`,
    )
  }

  // Facebook Auth

  public setFacebookAuthToken(params: {
    accessToken: string
    userID: string
  }): Promise<AxiosResponse> {
    return this.caller.axios.post(`/v2/oauth/facebook/token`, params)
  }

  public getFacebookAuthStatus(): Promise<AxiosResponse> {
    return this.caller.axios.get(`/v2/oauth/facebook/status`)
  }
}
